export default {
  days: '日, 月, 火, 水, 木, 金, 土',
  months: '1月, 2月, 3月, 4月, 5月, 6月, 7月, 8月, 9月, 10月, 11月, 12月',
  monthNames: '1月, 2月, 3月, 4月, 5月, 6月, 7月, 8月, 9月, 10月, 11月, 12月',
  dayNames: '日, 月, 火, 水, 木, 金, 土',
  today: '今日',
  yesterday: '昨日',
  lastWeek: '先週',
  thisWeek: '今週',
  thisMonth: '今月',
  lastMonth: '先月',
  last7Days: '過去7日間',
  last14Days: '過去14日間',
  last30Days: '過去30日間',
  closeCalendar: '設定する',
  custom: 'カスタム',
  lifetime: '通算',
  day: '日',
  month: '月',
  year: '年'
};
