export default {
  page_title: '商取引',
  amount: '予算（税込）',
  campaign_name: 'キャンペーン名',
  gmo_id: 'GMO ID',
  created_at: '取引日',
  initial_amount: '予算',
  system_fee: '運用フィー額',
  system_fee_rate: '運用フィー',
  settlement_fee_rate: '決済手数料',
  user_name: 'ユーザー',
  user_email: 'メールアドレス',
  total_system_fee: '運用フィー額',
  entity: '商取引',
  cancel_modal_title: '取引キャンセル',
  confirm_description: '本当に返金しますか？',
  confirm_execute_button: '返金実行',
  user_id: 'GMO ID (USER ID)',
  detail: '詳細',
  provider: '広告媒体',
  payment_gateway_type: 'ペイメントゲートウェイタイプ',
  status: 'ステータス',
  paid: '成功',
  rejected: '拒否',
  pending: '未決',
  failed: '失敗',
  refund: '返金済',
  cancel: 'キャンセル',
  gateway_manual: '手動追加',
  plan: 'プランタイプ',
  admin: '管理者',
  is_coupon: 'クーポン',
  uninstallation: '※アプリの削除'
};
