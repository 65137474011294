
import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';

@Component({})
export default class App extends Vue {
  @Action('loader/setDevice') public setDevice: any;

  get layout() {
    if ((this.$route as any).meta.layout === 'none') {
      return 'div';
    }
    return ((this.$route as any).meta.layout || 'default') + '-layout';
  }

  created() {
    addEventListener('resize', () => {
      this.setDevice(window.innerWidth < 768 ? 'is-mobile' : window.innerWidth < 1025 ? 'is-tablet' : 'is-desktop');
    });
  }
}
