export const dashboardRoutes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      title: 'ダッシュボード',
      layout: 'dashboard',
      isPrivate: true
    },
    component: () => import('@/modules/dashboard')
  },
  {
    path: '/campaigns',
    name: 'campaigns',
    meta: {
      title: 'キャンペーン',
      layout: 'dashboard',
      isPrivate: true
    },
    component: () => import('@/modules/campaigns')
  },
  {
    path: '/transactions',
    name: 'transactions',
    meta: {
      title: '商取引',
      layout: 'dashboard',
      isPrivate: true
    },
    component: () => import('@/modules/transactions')
  },
  {
    path: '/users',
    name: 'users',
    meta: {
      title: 'ユーザー',
      layout: 'dashboard',
      isPrivate: true
    },
    component: () => import('@/modules/users')
  },
  {
    path: '/admins',
    name: 'admins',
    meta: {
      title: '管理者管理',
      layout: 'dashboard',
      isPrivate: true
    },
    component: () => import('@/modules/admins')
  },
  {
    path: '/ng-words',
    name: 'ng_words',
    meta: {
      title: 'ng_words',
      layout: 'dashboard',
      isPrivate: true
    },
    component: () => import('@/modules/ngWords')
  },
  {
    path: '/affiliate-users',
    name: 'affiliate-users',
    meta: {
      title: 'アフィリエイトユーザー',
      layout: 'dashboard',
      isPrivate: true
    },
    component: () => import('@/modules/affiliateUsers')
  },
  {
    path: '/themes',
    name: 'themes',
    meta: {
      title: 'テーマ',
      layout: 'dashboard',
      isPrivate: true
    },
    component: () => import('@/modules/themes')
  },
  {
    path: '/notices',
    name: 'notices',
    meta: {
      title: 'お知らせ',
      layout: 'dashboard',
      isPrivate: true
    },
    component: () => import('@/modules/notices')
  },
  {
    path: '/images',
    name: 'images',
    meta: {
      title: '画像',
      layout: 'dashboard',
      isPrivate: true
    },
    component: () => import('@/modules/images')
  },
  {
    path: '/manual',
    name: 'manual',
    meta: {
      title: 'マニュアル更新',
      layout: 'dashboard',
      isPrivate: true
    },
    component: () => import('@/modules/manual')
  },
  {
    path: '/logs',
    component: () => import('@/modules/logs/ApiParentRoute.vue'),
    children: [
      {
        path: 'system',
        name: 'systemLogs',
        meta: {
          title: 'Systemログ',
          layout: 'dashboard',
          isPrivate: true
        },
        component: () => import('@/modules/logs/SystemLogs.vue')
      },
      {
        path: 'chatbot',
        name: 'chatbotLogs',
        meta: {
          title: 'Chatbotログ',
          layout: 'dashboard',
          isPrivate: true
        },
        component: () => import('@/modules/logs/ChatbotLogs.vue')
      }
    ]
  },
  {
    path: '/business-users',
    name: 'businessUsers',
    meta: {
      title: 'Business Users',
      layout: 'dashboard',
      isPrivate: true
    },
    component: () => import('@/modules/accountPages/businessUsers/BusinessUsersTable.vue')
  },
  {
    path: '/google-accounts',
    name: 'googleAccounts',
    meta: {
      title: 'Google Accounts',
      layout: 'dashboard',
      isPrivate: true
    },
    component: () => import('@/modules/accountPages/googleAccounts/GoogleAccountsTable.vue')
  },
  {
    path: '/facebook-accounts',
    name: 'facebookAccounts',
    meta: {
      title: 'Facebook Accounts',
      layout: 'dashboard',
      isPrivate: true
    },
    component: () => import('@/modules/accountPages/facebookAccounts/FacebookAccountsTable.vue')
  },

  {
    path: '/profile',
    name: 'profile',
    meta: {
      title: 'Profile',
      layout: 'dashboard',
      isPrivate: true
    },
    component: () => import('@/modules/profile')
  },
  {
    path: '/daily-budget',
    name: 'daily-budget',
    meta: {
      title: '１日予算設定',
      layout: 'dashboard',
      isPrivate: true
    },
    component: () => import('@/modules/dailyBudgetManagement')
  },
  {
    path: '/providers',
    name: 'providers',
    meta: {
      title: 'providers',
      layout: 'dashboard',
      isPrivate: true
    },
    component: () => import('@/modules/providers')
  }
];
