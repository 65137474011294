export default {
  page_title: 'Googleアカウント',
  meta_tag_installation: 'メタタグ設置',
  account_created_at: '作成日',
  account_id: 'アカウントID',
  account_budget: '予算',
  user_id: 'ユーザー ID',
  user_name: 'ユーザー名',
  user_email: 'メールアドレス',
  rejected_product_count: '拒否された商品数',
  total_product_count: '全商品数',
  site_verification: 'サイト確認ステータス',
  site_claim_date: 'サイト申請日時',
  site_verification_claim_success: '完了済み',
  site_verification_claim_error: 'エラー',
  site_verification_verify_pending: '未確認',
  rejected_product_status: '商品不承認ステータス',
  rejected_product_status_all_rejected: '全商品不承認',
  rejected_product_status_some_rejected: '一部不承認',
  rejected_product_status_all_approved: 'なし',
  run_site_verification: 'ウェブサイトの所有権の確認と申請',
  site_owners_update: 'ウェブサイトのオーナーを更新',
  meta_tag: 'メタタグ',
  google_modal_warning:
    'サイト確認ステータスが<br/>「未確認」の場合: ユーザーさんのショップに設置されたメタタグが以下と同じか確認してください。<br/>「エラー」の場合で申請権限がない場合: 「ウェブサイトのオーナーを更新」をクリックしてください。',
  check_meta_tag: 'タグ確認する',
  meta_tag_found: 'Googleタグが見つかりました。',
  tag_installation_completed: '完了',
  tag_installation_incompleted: '未完了',
  update_account_button: '編集',
  update_account_error: '更新に失敗しました。',
  update_account_confirm_modal_title: 'Googleアカウントの更新',
  update_account_confirm_modal_content: '{user} のGoogle広告アカウントを更新されます。よろしいでしょうか？'
};
