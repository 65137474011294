import Vue from 'vue';
import router from './router';
import App from './modules/App.vue';
import store from '@/_store';
import Buefy from 'buefy';
import '@/assets/sass/style.scss';

import i18n from './i18n';
import i18nMixin from '@/i18nMixin';
import '@/_helpers/directives';
import Vuelidate from 'vuelidate';

// Sentry
import * as Sentry from '@sentry/vue';
import { ReportingObserver as ReportingObserverIntegration } from '@sentry/integrations';

import { DefaultLayout, DashboardLayout } from '@/shared/components/layout';
import './vee-validate.ts';
Vue.component('default-layout', DefaultLayout);
Vue.component('dashboard-layout', DashboardLayout);

Vue.mixin(i18nMixin);
Vue.use(Buefy);
Vue.use(Vuelidate);

Vue.config.productionTip = false;

Sentry.init({
  Vue: Vue,
  dsn: 'https://5916fbb7b21645149ba1fb9e5e7ccea8@o361280.ingest.sentry.io/4504909948846080',
  release: 'Chokusai Admin@' + process.env.VUE_APP_VERSION,
  integrations: [new ReportingObserverIntegration()],
  tracingOptions: {
    trackComponents: true
  },
  debug: true,
  environment: process.env.VUE_APP_ENV
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
