export default {
  system_log: {
    page_title: 'SYSTEMログ',
    entity: 'SYSTEMログ'
  },
  chatbot_log: {
    page_title: 'CHATBOTログ',
    entity: 'CHATBOTログ'
  }
};
