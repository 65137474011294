
import { Vue, Component } from 'vue-property-decorator';
import DashboardLayoutMenu from './DashboardLayoutMenu.vue';
import DashboardLayoutFooter from './DashboardLayoutFooter.vue';
import { Getter } from 'vuex-class';

@Component({
  components: {
    DashboardLayoutFooter,
    DashboardLayoutMenu
  }
})
export default class DashboardLayout extends Vue {
  @Getter('isLoading', { namespace: 'loader' })
  public isLoading!: boolean;
  @Getter('isCollapsed', { namespace: 'loader' })
  public isCollapsed!: boolean;
}
