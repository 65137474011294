export default {
  basic_command: '基本コマンド',
  create_audiences: '初期オーディエンス作成({provider})',
  create_audiences_180: '180日オーディエンス作成',
  create_lookalike_audiences: '類似オーディエンス作成 ({provider})',
  lookalike_audiences_creation_warning: '全ての類似オーディエンスはすでに作成しました。',
  custom_fund: 'カスタム集金',
  fund_type: '集金タイプ',
  please_pick: '選択してください',
  fund_amount: '集金額',
  fund_it: '集金実行',
  accumulative_insight: 'インサイト集計',
  period: '期間',
  refresh_insights: '{provider}インサイト更新',
  all: '全て'
};
