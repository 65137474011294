import validation from './validation';
import admins from './admins';
import affiliateUser from './affiliateUser';
import auth from './auth';
import business_users from './business_users';
import campaign from './campaign';
import captcha from './captcha';
import daily_budget from './daily_budget';
import dashboard from './dashboard';
import dates from './dates';
import domainTable from './domainTable';
import facebook_accounts from './facebook_accounts';
import facebook from './facebook';
import form from './form';
import google_accounts from './google_accounts';
import industry from './industry';
import insight from './insight';
import log from './log';
import menu from './menu';
import modal from './modal';
import ng_words from './ng_words';
import notice from './notice';
import plan from './plan';
import redirects from './redirects';
import shop from './shop';
import table from './table';
import themes from './themes';
import transaction from './transaction';
import user_command from './user_command';
import user_log from './user_log';
import user from './user';
import providers from './providers';

export const translations = {
  jp: {
    validation,
    admins,
    affiliateUser,
    auth,
    business_users,
    campaign,
    captcha,
    dashboard,
    daily_budget,
    dates,
    domainTable,
    facebook_accounts,
    facebook,
    form,
    google_accounts,
    industry,
    insight,
    log,
    menu,
    modal,
    ng_words,
    notice,
    plan,
    redirects,
    shop,
    table,
    themes,
    transaction,
    user_command,
    user_log,
    user,
    providers
  }
};
