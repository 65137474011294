export default {
  page_title: 'Facebookアカウント',
  audience_page_title: 'オーディエンス更新',
  account_created_at: '作成日',
  meta_tag_installation: 'メタタグ設置',
  meta_tag: 'メタタグ',
  user_name: 'ユーザー',
  user_email: 'メールアドレス',
  ad_account_id: '広告アカウントID',
  instagram_id: 'インスタグラムID',
  page_id: 'ページID',
  verified: '認証済み',
  not_verified: '認証されてない',
  shop_url: '採用サイトURL',
  pixel_id: 'ピクセルID',
  check_meta_tag: 'タグ確認する',
  meta_tag_found: 'Facebookのドメイン認証用タグが見つかりました。',
  tag_installation_completed: '完了',
  tag_installation_incompleted: '未完了',
  update_account_error: '更新に失敗しました。',
  update_account_confirm_modal_title: 'FacebookアカウントIDの更新',
  update_account_confirm_modal_content: '{user} のFacebook広告アカウントIDを更新されます。よろしいでしょうか？',
  audience_name: 'オーディエンス名',
  audience_id: 'オーディエンスID',
  no_audience: 'オーディエンスがないです',
  tooltip_update_audience: 'ID変更'
};
