export default {
  title: 'LINEアカウント認証',
  captcha: 'キャプチャを入れてください',
  captchaLabel: 'キャプチャ',
  noCaptcha: 'チャプチャのリクエストがありません。',
  failed: 'チャプチャの記入ができませんでした。',
  success: '送信しました。',
  captchaConfirmTitle: 'キャプチャをコールしますか',
  captchaConfirmContent: 'キャプチャのEメールが届きましたか？BOTの位置を読むのは１５秒ぐらいかかります。',
  nothing: 'もしLINEダッシュボードの画像が見られたら、スクレーパーはもう問題なくログインしたことです。'
};
