export default {
  page_title: 'キャンペーン',
  ng_word: '違反単語',
  deposit: 'デポジット額',
  campaign_budget: '予算',
  operation_fee: '運用フィー',
  campaign_daily_budget: '1日の予算',
  campaign_required_setup_audiences_flag: 'オーディエンス設定フラグ',
  campaign_required_setup_audiences_flag_true: 'あり',
  campaign_name: 'キャンペーン名',
  campaign_provider: '広告媒体',
  campaign_user: 'ユーザー',
  campaign_user_email: 'メールアドレス',
  plan: 'プラン',
  campaign_status: 'ステータス',
  campaign_strategy: '戦略',
  campaign_strategy_offensive: '攻め',
  campaign_strategy_defensive: '守り',
  campaign_strategy_standard: '標準',
  campaign_spend: '利用金額合計',
  campaign_impressions: '表示回数',
  campaign_clicks: 'クリック数',
  campaign_cpc: 'クリック単価',
  campaign_conversions: '獲得数',
  campaign_cost_per_conversion: '獲得数単価',
  conversions_value: '広告経由の売上',
  audience_count: 'オーディエンス数',
  audience_list_title: '接続オーディエンス',
  entity: 'キャンペーン',
  industry: '業種',
  industry_type: '業種タイプ',
  industry_type_ec: 'EC',
  industry_type_non_ec: 'NON-EC',
  ng_word_tooltip: '違反単語が使われています。',
  ng_word_exists: 'あり',
  ng_word_not_exists: 'なし',
  gmo_id: 'GMO ID (USER ID)'
};
