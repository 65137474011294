export default {
  page_title: 'ビジネスユーザー',
  update_record_button: 'リストの更新',
  assign_all_to_all_ad_accounts: 'すべてのユーザーの割当',
  assign_all_to_all_ad_accounts_confirmation:
    'すべてのビジネスユーザーをすべての広告アカウントに割り当てますが、よろしいですか？',
  account_created_at: '作成日',
  account_id: 'アカウントID',
  name: '名前',
  role: '役割',
  email: 'メールアドレス',
  add: 'アカウント追加',
  show_unassigned: 'Facebookビジネスユーザーの追加',
  get_unassign_error: '割り当てられないユーザーリストを取得できませんでした。',
  add_error: 'ビジネスユーザーの割り当てに失敗しました。',
  select_label: 'どのビジネスユーザーと連携しますか。',
  assign_to_all: 'ビジネスユーザーにすべての広告アカウントを連携します。',
  assign_to_all_pages: '以下のビジネスユーザーをすべてのページに割り当てますか？',
  no_business_user_error: 'こちらのユーザーはすべてのビジネスユーザーと連携済みです。',
  assigned_ad_accounts_count: '割り当てられた広告アカウント数',
  assigned_pages_count: '割り当てられたページ数',
  job_status: 'ジョブのステータス',
  is_attachable: 'アクティブ'
};
