export default {
  accounts: '媒体情報',
  admins: '管理者',
  adsistSettings: 'チョク採設定',
  ad_accounts: 'ADアカウント',
  affiliateUsers: 'アフィリエイト',
  business_users: 'FB割当設定',
  campaigns: 'キャンペーン',
  chatbot_logs: 'CHATBOTログ',
  dashboard: 'ダッシュボード',
  provider_accounts: '{provider}アカウント',
  images: '画像',
  manual: 'マニュアル更新',
  industries: '業界',
  logout: 'ログアウト',
  logs: 'ログ',
  notices: 'お知らせ',
  plans: 'プラン',
  profile: 'プロフィール',
  settings: 'アカウント',
  system_logs: 'SYSTEMログ',
  themes: 'テーマ',
  transactions: '商取引',
  users: 'ユーザー',
  ng_words: '違反単語',
  daily_budget: '１日予算設定',
  providers: 'おすすめキャンペーン'
};
