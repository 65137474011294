import { extend } from 'vee-validate';
import { required, email, min, alpha, numeric, min_value } from 'vee-validate/dist/rules';
import i18n from '@/i18n';

const getShiftJISByteLengthForGoogle = (s: any) => {
  if (!s) return 0;
  // count japanese characters as two-bytes and some special characters are counted as 1 on Google
  let strLen = s.replace(/[^\x00-\x80｡｢｣､･ｦｧｨｩｪｫｬｭｮｯｰｱｲｳｴｵｶｷｸｹｺｻｼｽｾｿﾀﾁﾂﾃﾄﾅﾆﾇﾈﾉﾊﾋﾌﾍﾎﾏﾐﾑﾒﾓﾔﾕﾖﾗﾘﾙﾚﾛﾜﾝ ﾞ ﾟ]/g, 'xx')
    .length;

  if (s.includes('´')) {
    strLen--;
  }
  if (s.includes('‐')) {
    strLen--;
  }
  return strLen;
};

const floatHundredth = new RegExp('^[-+]?\\d*(\\.\\d{1,2})?([eE]{1}[-]?\\d+)?$');

const urlRegexPattern = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
  'i'
);

extend('required', {
  ...required,
  message: () => i18n.t('validation.required').toString()
});

extend('email', {
  ...email,
  message: () => i18n.t('validation.email_format').toString()
});

extend('min', {
  ...min,
  message: () => i18n.t('validation.min_password').toString()
});

extend('minNum', {
  validate: (value, args: any) => {
    return value >= args.min;
  },
  message: (value, args) => i18n.t('validation.min_num', { min: args.min }).toString(),
  params: ['min']
});

extend('decimalTwoPlace', {
  validate: value => {
    return floatHundredth.test(value);
  },
  message: () => i18n.t('validation.decimal_too_much').toString()
});

extend('url', {
  validate: value => urlRegexPattern.test(value.toLowerCase()),
  message: () => i18n.t('validation.url').toString()
});

extend('minLength', {
  validate(value, args: any) {
    return getShiftJISByteLengthForGoogle(value) >= args.min;
  },
  message: (value, args: any) => {
    if (args.needErrorMessage) {
      return i18n.t('validation.fb_page_id').toString();
    }
    return '';
  },
  params: ['min', 'needErrorMessage']
});

extend('alpha', {
  ...alpha,
  message: () => i18n.t('validation.alpha').toString()
});

extend('numeric', {
  ...numeric,
  message: () => i18n.t('validation.numeric').toString()
});

extend('maxLength', {
  validate(value, args: any) {
    return getShiftJISByteLengthForGoogle(value) <= args.max;
  },
  message: (value, args: any) => {
    if (args.isTel) {
      return i18n.t('validation.tel_over').toString();
    }
    return i18n
      .t('validation.max_length', {
        full_width: args.max / 2,
        half_width: args.max
      })
      .toString();
  },
  params: ['max', 'isTel']
});

extend('isOldPassword', {
  validate(value, arg: any) {
    return arg[0] !== 'false';
  },
  message: () => i18n.t('validation.not_same_old').toString()
});

extend('confirmed', {
  validate(value, arg: any) {
    return arg[0] === value;
  },
  message: () => i18n.t('validation.not_same').toString()
});

extend('isDefaultPassword', {
  validate(value) {
    return value !== 'corekarapass';
  },
  message: () => i18n.t('validation.shouldnt_use_default_password').toString()
});

extend('duplicatedEmail', {
  validate(value, arg: any) {
    if (arg[0] === 'true') {
      return false;
    } else {
      return true;
    }
  },
  message: () => i18n.t('validation.duplicated_email').toString()
});

extend('minDailyBudget', {
  ...min_value,
  message: (value, args: any) => i18n.t('validation.provider_daily_budget', { min: args.min }).toString(),
  params: ['min']
});
