export default {
  required: '必ずご入力ください',
  email_format: 'メールアドレスが不正です。',
  duplicated_email: 'メールアドレスは既に使われています。',
  invalid_credentials: 'ログインに失敗しました。メールアドレスとパスワードをご確認下さい。',
  shouldnt_use_default_password: '初期パスワード以外にしてください。',
  not_same: 'パスワードは一致ではありません。',
  not_same_old: '旧パスワードとは一致ではありません。',
  max: '範囲を超えています',
  min: '範囲を下回っています',
  min_password: 'パスワードは８文字以上にしてください。',
  max_length: 'あら！文字数オーバーです。(全角:{full_width}, 半角:{half_width})',
  alpha: 'ごめんね、アルファベットだけにして〜',
  numeric: 'ちょっと、整数だけくださいよー',
  decimal_too_much: 'もうダメ、小数の限界です',
  tel_over: 'こんなに長い電話番号見たことあるの？！',
  url: '入れたのはマジでURL?🥸',
  min_num: 'この数字はここであり得る？{min}より大きい数字にしてな',
  fb_page_id: 'FBを使ったことないの？正しいFB IDを入れて！',
  alphabetic_characters_only: '半角英数字8~12桁にてご入力ください',
  halfwidth_characters_only: '半角でご入力ください',
  maxLength: '{length}文字以内で入力をお願いします。',
  minLength: '文字の十分な数ではありません。',
  shouldMatch: 'このフィールドは前のものと一致する必要があります',
  unique_sequence_number: 'この番号はすでに使用されています',
  missingHttp: '正しいURLを入れてください',
  ng_word_in_use: '「{entity}」は既に使用されています。',
  provider_daily_budget: '１日予算は{min}より大きい値に設定してください。',
  phone_numbers_not_allowed: '電話番号は使用できません。'
};
