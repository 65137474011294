export default {
  page_title: 'アフィリエイトユーザー管理',
  basicInfo: '基本情報',
  advancedSkinFeatures: 'スキン機能',
  companyUrl: '会社URL',
  affiliateFee: '手数料',
  name: 'パートナー名',
  tag: 'タグ',
  logoNormal: 'ロゴ',
  logoSmall: 'ロゴ(小)',
  logoFavicon: 'ロゴ(タブ)',
  theme: 'テーマ色',
  email: 'メールアドレス',
  tel: '電話番号',
  totalUsers: 'ユーザー数',
  totalAffiliateFee: '総合獲得',
  contract_started_date: '契約開始日',
  fbLink: 'FBページ',
  useOwnPlan: '特別プラン設定',
  planPriority: '特別プラン優先順位設定',
  statusTooltip: '特別プラン設定を使用される前に、プランを用意してください',
  customTag: 'タグの設定',
  imageHelper:
    "<table class='table is-bordered is-striped is-narrow is-fullwidth'><thead><tr><th>画像</th><th>比率(長:高)</th><th>目安(px)</th></tr></thead><tbody><tr><td>メイン</td><td>4:1</td><td>140*40</td></tr><tr><td>サブ</td><td>1:1</td><td>50*50<br></td></tr><tr><td>ファビコン</td><td>1:1</td><td>16*16</td></tr></tbody></table>",
  download: 'ダウンロード',
  defaultFileName: 'アフィリエイト',
  selectMonth: 'CSVの期間',
  main: 'メイン',
  sub: 'サブ',
  favicon: 'ファビコン',
  case_num: '件数',
  case: '件',
  spend: '円',
  case_until: '{comparate}まで',
  total_user: '件数',
  total_net_spent: '商流金額',
  charge_by: '手数料設定'
};
