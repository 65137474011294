export default {
  csv_email_success: 'こちらの作業が完了するまでに数分かかる可能性がありますので、しばらくお待ちください。',
  csv_email_error: 'システムエラーが発生しました。もう一度お試しください。',
  error_change_budget: 'キャンペーン予算変更は失敗しました。',
  warning_change_budget: '必ずビジネスマネージャーも予算を更新してください。',
  ng_words_campaign_name: '景品単語：{name}',
  ng_words_close: '閉じる',
  ng_words_exists: '「 {name} 」 広告は違反単語を使用されています。',
  ng_words_list: '違反単語：',
  display: 'ティスプレイ広告',
  search: '検索広告',
  google_ssc: 'スマートショッピング広告',
  google_pla: 'ショッピング広告',
  google_pmax: 'パフォーマンス広告',
  campaign_type: 'ネットワーク',
  ai_campaign: 'AI実装されたCP',
  ai_campaign_installed: '実装された',
  ai_campaign_not_installed: '実装されていない',
  send_email: 'メールで送る',

  // Ad list Modal
  ad_list_modal: {
    title: '広告リスト',
    name: '広告名',
    ad_account_id: '広告アカウントID',
    adset_id: '広告ID',
    required_setup_audiences: 'オーディエンス設定フラグ',
    required_setup_audiences_on: '設定してください',
    required_setup_audiences_off: '設定済み',
    update_failed: '更新に失敗しました。'
  }
};
