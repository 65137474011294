export default {
  actions: 'アクション',
  search_placeholder: 'キーワード',
  filter_button_text: '列',
  no_records: '現在は何もありません。',
  filter_select: '選択してください',
  date_picker_placeholder: '集計期間',
  flag_category: 'フラグ',
  active_category: 'アクティブ'
};
