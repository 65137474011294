import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from '@/_store/types';
import { user } from '@/_store/modules/user/index';
import { loader } from '@/_store/modules/loader/index';
import { affiliateUser } from '@/_store/modules/affiliateUser/index';
import { admin } from '@/_store/modules/admin/index';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  state: {
    version: '1.0.0'
  },
  modules: {
    user,
    loader,
    affiliateUser,
    admin
  }
};

export default new Vuex.Store<RootState>(store);
