
import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';

@Component
export default class DashboardLayoutMenu extends Vue {
  @Action('user/logUserOut') public logoutAction: any;
  @Action('loader/toggleDashboardMenu') public toggleDashboardMenu: any;

  public isCollapsed = false;

  public sideMenuLinks: object[] = [];
  public dropdownLinkGroups: object[] = [];

  public adsistSettings: any;
  public logLinks: any;
  public accountLinks: any;

  created() {
    this.sideMenuLinks = [
      {
        path: '/dashboard',
        name: this.$t('menu.dashboard'),
        icon: 'fas fa-signal'
      },
      {
        path: '/campaigns',
        name: this.$t('menu.campaigns'),
        icon: 'fas fa-chart-bar'
      },
      {
        path: '/transactions',
        name: this.$t('menu.transactions'),
        icon: 'fas fa-yen-sign'
      },
      {
        path: '/users',
        name: this.$t('menu.users'),
        icon: 'fas fa-users'
      },
      {
        path: '/notices',
        name: this.$t('menu.notices'),
        icon: 'far fa-newspaper'
      }
    ];

    this.adsistSettings = {
      icon: 'fas fa-american-sign-language-interpreting',
      name: this.$t('menu.adsistSettings'),
      isOpen: false,
      links: [
        {
          path: '/images',
          name: this.$t('menu.images')
        },
        {
          path: '/manual',
          name: this.$t('menu.manual')
        },
        {
          path: '/affiliate-users',
          name: this.$t('menu.affiliateUsers')
        },
        {
          path: '/themes',
          name: this.$t('menu.themes')
        },
        {
          path: '/admins',
          name: this.$t('menu.admins')
        },
        {
          path: '/ng-words',
          name: this.$t('menu.ng_words')
        },
        {
          path: '/daily-budget',
          name: this.$t('menu.daily_budget')
        },
        {
          path: '/providers',
          name: this.$t('menu.providers')
        }
      ]
    };

    this.logLinks = {
      icon: 'far fa-file-alt',
      name: this.$t('menu.logs'),
      isOpen: false,
      links: [
        {
          path: '/logs/system',
          name: this.$t('menu.system_logs')
        },
        {
          path: '/logs/chatbot',
          name: this.$t('menu.chatbot_logs')
        }
      ]
    };

    this.accountLinks = {
      icon: 'fas fa-users',
      name: this.$t('menu.accounts'),
      isOpen: false,
      links: [
        {
          path: '/facebook-accounts',
          name: this.$t('menu.provider_accounts', { provider: 'Facebook' })
        },
        {
          path: '/business-users',
          name: this.$t('menu.business_users')
        },
        {
          path: '/google-accounts',
          name: this.$t('menu.provider_accounts', { provider: 'Google' })
        }
      ]
    };

    this.dropdownLinkGroups = [this.adsistSettings, this.accountLinks, this.logLinks];
  }

  groupHover(index: number) {
    let name = '';

    switch (index) {
      case 0:
        name = 'one';
        break;
      case 1:
        name = 'two';
        break;
      case 2:
        name = 'three';
        break;
    }

    return name;
  }

  toggleAppMenu(): boolean {
    this.toggleDashboardMenu(!this.isCollapsed);
    return (this.isCollapsed = !this.isCollapsed);
  }

  logoutClickHandler() {
    this.logoutAction();

    this.$router.push({ name: 'login' });
  }

  updateScroll(): void {
    // clearTimeout(timeout);
    let container = this.$refs['scroll-pad'] as HTMLElement;

    setTimeout(() => {
      container.scrollTop = container.clientHeight;
    }, 100);
  }
}
