export default {
  cancel: 'キャンセル',
  create: '制作',
  create_by_scraper: '自動制作',
  edit: '編集',
  delete: '削除',
  confirm: '確定',
  yes: 'はい',
  no: 'いいえ',
  copy: 'コピー',
  has_unsaved_changes: '編集をやめますか？',
  broadcast_prompt_message: 'ライブメッセージとして作成しますか',
  broadcast_warning:
    'ライブメッセージは全ユーザーに配信されます。また、一度配信すると編集できません。以上を了承された場合は、「<strong>確認</strong>」と入力してください',
  broadcast_confirmation_key: '確認',
  archive_prompt_confirm: '確定',
  archive_prompt_message: 'この{entity}をアーカイブしますか？',
  unarchive_prompt_message: 'この{entity}を有効にしますか？',
  last_item_warning_while_archiving: 'このプランはアフィリ最後のプランなので、アーカイブできません。',
  display_plans_at_max: '同じアフィリのプランは３つ表示されていますので、こちらのプランを表示されられません。',
  delete_prompt_confirm: '削除',
  delete_prompt_message: 'この{entity}を削除しますか？',
  ng_word_delete_prompt_message: '「{entity}」を削除しますか？',
  total_user_number: 'ユーザー',
  active_user_number: 'アクティブユーザー',
  copy_success: 'コピーしました！',
  copy_failure: 'コピーに失敗しました！',
  generic_error: '何か問題が発生しました！',
  update_success: '成功しました！',
  pls_wait:
    'こちらの作業が完了するまでに数分かかる可能性がありますので、しばらくお待ちください。数分後、ページを再読み込みしてしてください。',
  reactive_prompt_message: '{entity}をアクティブに変更しますか？',
  deactive_prompt_message: '{entity}を停止しますか？',
  under_review_prompt_message: '{entity}を停止しますか？',
  change_status_prompt_message: '{entity}のステタスを変更しますか？',
  send_reminder_prompt_message: 'この{entity}を再送信します？',
  send_reminder_prompt_confirm: '再送信',
  shop_tag_install_success: 'インストールが完成しました！',
  shop_tag_install_failed: 'インストールが失敗しました。',
  updated_success: '編集しました！',
  updated_failed: '編集が失敗しました。。。',
  show_password: 'パスワードを表示する',
  email: 'メールアドレス',
  password: 'パスワード',
  update_daily_budget: '１日予算の変更は全てのユーザーに影響がありますので、変更でよろしいですか？',
  confirmation_charge_by_updated: '手数料設定を確認してください！値は件数か円かをよく見てくださいね。',
  searching: '🧐 頑張って探しています!',
  refresh_button: '更新ボタン'
};
