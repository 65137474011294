export default {
  page_title: 'プラン',
  charge: '運用フィー',
  interval: '間隔',
  description: '説明',
  price: '価格',
  price_usd: '米ドル価格',
  status: 'ユーザーに表示',
  name: 'プラン名',
  type: 'プランタイプ',
  interval_day: '日',
  interval_week: '週',
  interval_month: '月',
  interval_year: '年',
  type_standard: 'スタンダード',
  type_inhouse: '社内',
  type_adsist_plus: 'AdSIST Plus',
  entity: 'プラン',
  default: 'なし',
  number_of_users: 'ユーザー数'
};
