export default {
  net_sales: '総売上高',
  arpu: 'ARPU',
  accounts: 'アカウント',
  active_users: 'アクティブアカウント',
  facebook: 'フェイスブック',
  number_of_campaings: 'キャンペーン数',
  users: 'ユーザー',
  plans: 'プラン',
  affiliates: 'アフィリエイト',
  shops: 'ショップ',
  campaigns: 'キャンペーン',
  activity_logs: 'ユーザーログ'
};
