export default {
  page_title: 'テーマ',
  name: 'テーマ名',
  primary: 'メイン',
  secondary: 'セカンド',
  success: '成功',
  backgroundDeep: '背景１',
  backgroundSurface: '背景２',
  textNormal: 'テキスト',
  textActive: 'テキスト',
  textHover: 'テキスト',
  subtitleSide: 'サブタイトル',
  paginationActive: 'ページ',
  dashboardClick: 'クリック',
  dashboardSpent: '利用金額',
  dashboardImpression: '表示回数',
  dashboardConversion: '獲得数',
  dashboardTitle: '広告予算管理'
};
