export default {
  page_title: 'ユーザー',
  user_id: 'ユーザー ID',
  gmo_id: 'GMO ID (USER ID)',
  email: 'メール',
  phone_number: '電話番号',
  provider: '広告媒体',
  account_created_at: '作成日',
  account_company: '会社名',
  account_email: 'メールアドレス',
  account_name: '名前',
  account_plan: '来月からのプラン',
  current_account_plan: '今月のプラン',
  plan_option: 'オプション利用',
  plan_option_yes: 'あり',
  plan_option_no: 'なし',
  tag_setting: 'タグ設定',
  csv_download: 'ダウンロード',
  account_status: 'ステータス',
  account_last_activity: '最後活動日',
  account_last_activity_null: 'まだ活動はありません',
  account_type: 'ユーザータイプ',
  account_type_standard: 'スタンダード',
  account_type_inhouse: '社内',
  status_code_incompleted: '未完了',
  status_code_completed: '完了',
  status_code_not_verified: '未認証',
  status_code_inactive: '停止',
  status_code_under_review: '審査中',
  affiliate: 'アフィリエイト',
  facebook_account_id: 'Facebook広告アカウントID',
  facebook_pixel_id: 'FacebookピクセルID',
  google_account_id: 'Google広告アカウントID',
  google_tag_id: 'GoogleタグID',
  facebook_url: 'Facebook URL',
  facebook_page: 'Facebookページ',
  using_adsist_facebook_page: 'チョク採',
  not_using_adsist_facebook_page: '自社',
  line_account: 'LINEアカウント',
  line_sync_account_status: '認証ステータス更新',
  line_synced: '更新しました',
  line_sync_account_status_error: '認証ステータスが失敗でした。',
  shop_industry: '業種',
  shop_industry_type: '業種タイプ',
  shop_industry_type_ec: 'EC',
  shop_industry_type_non_ec: 'NON-EC',
  shop_name: 'ショップ名',
  shop_site_name: 'サイト名',
  shop_site_url: 'サイトURL',
  shop_corporate_url: '貴社サイトURL',
  shop_url: '採用サイトURL',
  shop_annual_sales: '年商 (¥)',
  form_title: 'ユーザー',
  form_fieldset_account: 'アカウント',
  form_fieldset_shop: '会社情報',
  form_fieldset_premium_info: '代表者情報',
  form_fieldset_logs: 'ログ',
  form_fieldset_facebook: 'Facebook',
  form_fieldset_google: 'Google',
  update_audience_success: '変更はまもなくAudienceに反映されます。',
  update_insight_success: '変更はまもなくInsightに反映されます。',
  update_insight_warning:
    'Facebookインサイト更新を使用すると、「7d_clickと1d_view」のアトリビューション設定が使用されます。</br>2021年01月19日以前の日付を更新する場合は、ご注意ください。',
  command_modal_title: '各種コマンド実行',
  has_account: '保有',
  have_no_account: '未保有',
  email_temporary_register: '仮登録のお知らせ',
  email_thank_you_register: '登録のお礼',
  email_reminder: 'リマインダー',
  email_send: '再送信',
  email_campaign: 'キャンペーン',
  email_ad: '広告',
  email_tag: 'タグ',
  email_send_confirmation: 'メールの送信が完了しました',
  flag_update_confirmation: '設定変更しました',
  entity: 'ユーザー',
  no_log_yet: 'まだログはありません',
  success_create_transaction: 'カスタム集金の徴収が完了しました。',
  confirm_title: 'カスタム集金',
  confirm_description: '本当に集金しますか？',
  confirm_execute_button: '集金実行',
  csv_download_error: 'システムエラーが発生しました。フィルターを変更して、もう一度お試しください。',
  business_user_facebook: '割り当て済みビジネスユーザー',
  line_tag_fail: 'LINEタグの取得は失敗しました、もう一度モダールを開いてくささい。',
  reload_five_minutes: '5分後にこのページをリロードしてください、ありがとう！',
  card_title: 'カード',
  card_no_info: 'クレジットカードの情報はありません',
  card_blocked_message: 'このユーザー現在カードのご登録が制限されております。',
  card_unlock_btn: '解除',
  card_number: 'カード番号',
  card_expire: '有効期限',
  card_brand: 'ブランド',
  card_holder_name: 'カードホルダー',
  card_delete_failed: 'エラーが発生しました',
  premium_info: {
    representative_name: '代表者名',
    person_in_charge_name: '担当者名',
    email: '連絡先メールアドレス',
    phone_number: '連絡先電話番号',
    expected_amount: 'ご利用予定予算金額 ',
    review_status: '承認ステータス',
    review_status_in_review: '審査中',
    review_status_approved: '承認済み',
    save_btn: '登録する'
  },
  parent_industry: '貴社業種分類1',
  child_industry: '貴社業種分類2',
  under_review: '審査中',
  inactive: '停止',
  completed: '完了',
  incompleted: '未完了',
  not_verified: '未認証'
};
