export default {
  page_title: '管理者',
  profile_page_title: 'アカウント情報',
  form_details: '管理者情報',
  active: 'アクティブ',
  name: '名前',
  email: '管理者 メールアドレス',
  entity: 'アドミンアカウント',
  password: 'パスワード',
  new_password: '新パスワード',
  new_password_confirm: '新パスワード確認',
  reset_password: 'パスワードのリセット',
  details: 'アドミン情報',
  default_password: '初期パスワード',
  password_reset_success: 'パスワードは初期パスワードにリセットされました。',
  upload_pdf_file_error: 'マニュアルのアップロードが失敗しました',
  upload_pdf_file_success: 'アップロードが完了しました',
  password_reset_msg: '新しいパスワードを作成してください'
};
