export default {
  page_title: '業界',
  order: '順番',
  name: '業界名',
  type: 'タイプ',
  type_ec: 'EC',
  type_nonec: 'NON-EC',
  amount: '予算',
  archive: 'アクティブ',
  entity: '業界'
};
